import {
  Box,
  Divider,
  Flex,
  Image,
  HStack,
  Text,
  BoxProps,
  Center,
  Stack,
  Button,
  Spacer,
  IconButton,
  Spinner,
  Tag,
  VStack,
  Show,
  Link,
  Grid,
  GridItem,
} from "@chakra-ui/react"
import { IProduct } from "../../../types"
import { ProductQuantityPicker } from "../domains/product/product-quantity-picker"
import { formatAsPrice, priceStyleSplitter } from "../../../utils/formatter"
import * as R from "ramda"
import { useMst } from "../../../lib/hooks/use-mst"
import { ShoppingCartIcon } from "../../../../public/images/icon/shopping-cart-icon"
import NextLink from "next/link"
import { DottedBox } from "../domains/product/dotted-box"
import { BellIcon, CloseIcon } from "@chakra-ui/icons"
import { useEffect, useState } from "react"
import { LightningBolt } from "../../../../public/images/icon/lightning-bolt"
import { ProductFollowButton } from "../domains/product/follow-button"
import { observer } from "mobx-react"
import { EProductDiscountType } from "../../../types"
import { ProductInventoryIndicator } from "../domains/product/product-inventory-indicator"
export interface IProductCardProps extends BoxProps {
  product: IProduct
  quantity: number
  compact?: boolean
  onClickClose?: (any) => void
  setQuantity: (updatedQuantity: number) => void
  handleAdd?: (any: any) => void
}

export const ProductCard = observer(
  ({ product, quantity, setQuantity, compact, onClickClose, ...boxProps }: IProductCardProps) => {
    const {
      sessionStore: { isLoggedIn },
      cartStore,
      productSearchStore: { isLoadingCustomerBestPrices },
    } = useMst()

    const [price, setPrice] = useState(priceStyleSplitter(product.price))
    useEffect(() => {
      if (product.effectiveSalesPrice) setPrice(priceStyleSplitter(product.effectiveSalesPrice || product.price))
    }, [product])

    const currentItem = cartStore.cart?.cartItems?.find(
      (cartItem) => product.anipetItemNumber == cartItem.product.anipetItemNumber,
    )

    let promotionUrl = product.promotionSlugs[0] && "/promotions"
    if (product.promotionSlugs.length == 1) promotionUrl = promotionUrl + `/${product.promotionSlugs[0]}`

    const pickerBlock = (product: IProduct, compact: boolean) => {
      if (product.comingSoon) {
        return (
          <VStack>
            <DottedBox>Coming Soon</DottedBox>
            <ProductFollowButton product={product} />
          </VStack>
        )
      }
      if (product.blocked || product.blockedBrand) {
        return <DottedBox>Currently Unavailable</DottedBox>
      }
      return (
        <Flex direction="column" h="full" align={{ base: "flex-start", md: "center" }} justify="center">
          <ProductQuantityPicker
            compact={compact}
            quantity={quantity}
            setQuantity={setQuantity}
            salesUnitOfMeasure={product.salesUnitOfMeasure}
            displaySUOM={false}
          />
        </Flex>
      )
    }

    return (
      <Box
        className="product-card-box"
        p={{ base: 2, md: 4 }}
        borderBottom="1px solid"
        borderColor="gray.200"
        {...boxProps}>
        <Grid
          h={{ base: "auto", md: "120px" }}
          templateRows={{ base: "repeat(12, auto)", md: "repeat(5, 1fr)" }}
          templateColumns={{ base: "repeat(4, 1fr)", md: "repeat(8, 1fr)", lg: "repeat(12, 1fr)" }}
          gap={1}>
          <GridItem alignContent={"center"} colSpan={{ base: 8, md: 1, lg: 1 }} rowSpan={{ base: 1, md: 5, lg: 5 }}>
            {product.sample && (
              <Center
                fontSize={compact ? "xs" : "sm"}
                fontWeight="semibold"
                color="white"
                top={0}
                h="20px"
                w={compact ? "64px" : "100px"}
                position="absolute"
                bg="background.brandOrange">
                SAMPLE
              </Center>
            )}
            {onClickClose && (
              <IconButton
                aria-label="Delete notification"
                bg="transparent"
                onClick={onClickClose}
                icon={<CloseIcon h={2} w={2} />}
                ml={{ base: "0", md: "2" }}
              />
            )}
            {!R.isEmpty(product?.imageUrls) && (
              <NextLink key={product?.id} href={`/products/${product?.slug || product?.id}`} passHref>
                <Image
                  minW={compact ? "64px" : "100px"}
                  w={compact ? "64px" : "100px"}
                  h={compact ? "64px" : "100px"}
                  src={product?.thumbImageUrls?.[0] || product?.imageUrls?.[0]}
                  objectFit="cover"
                  alt="product image"
                />
              </NextLink>
            )}
          </GridItem>

          <GridItem colSpan={{ base: 8, md: 7, lg: 11 }} gridRow={{ base: "2", md: "auto" }}>
            {promotionUrl && (
              <NextLink href={promotionUrl} passHref>
                <Text
                  color="background.brandBlueSecondary"
                  cursor="pointer"
                  fontWeight="bold"
                  _hover={{ textDecoration: "underline" }}>
                  Promotion Available!
                </Text>
              </NextLink>
            )}
          </GridItem>

          <GridItem colSpan={{ base: 8, md: 4, lg: 9 }} gridRow={{ base: "3", md: "auto" }}>
            {" "}
            <NextLink
              key={product?.id}
              className="product-card-name-link"
              href={`/products/${product?.slug || product?.id}`}
              passHref>
              {product?.name}
            </NextLink>
          </GridItem>
          <GridItem colSpan={{ base: 4, md: 2, lg: 1 }} gridRow={{ base: "8", md: "auto" }}>
            {!!product.effectiveSalesPrice && parseFloat(product.price) > product.effectiveSalesPrice && (
              <Flex w={compact ? "124px" : "160px"} justifyContent={{ base: "flex-start", md: "flex-end" }}>
                <Text fontSize="xl" color="gray.400" as="s" fontFamily={"Pathway Gothic One"}>
                  {formatAsPrice(product.price)}
                </Text>
              </Flex>
            )}
          </GridItem>
          <GridItem colSpan={1} display={{ base: "none", md: "block" }}></GridItem>

          <GridItem colSpan={{ base: 8, md: 3, lg: 8 }} gridRow={{ base: "4", md: "auto" }}>
            <HStack>
              <Text color="gray.400" fontSize="16px">
                Anipet Item No.
              </Text>
              <Text color="gray.400" fontSize="18px">
                {product?.anipetItemNumber}
              </Text>
            </HStack>
          </GridItem>
          <GridItem colSpan={{ base: 4, md: 1, lg: 1 }} gridRow={{ base: "7", md: "auto" }}>
            {currentItem?.quantity > 0 && (
              <Box justifyContent="center" minW="50px">
                <Text color="gray" textAlign={{ base: "left", md: "center" }}>
                  {currentItem.quantity} in <ShoppingCartIcon />
                </Text>
              </Box>
            )}
          </GridItem>
          <GridItem colSpan={{ base: 3, md: 2, lg: 1 }} rowSpan={{ base: 3, md: 2 }}>
            {isLoggedIn && pickerBlock(product, compact)}
          </GridItem>
          <GridItem colSpan={{ base: 5, md: 1, lg: 1 }} rowSpan={{ base: 3, md: 3 }}>
            {isLoggedIn && (
              <Flex
                direction={{ base: "column", md: "column" }}
                align="flex-end"
                justify="center"
                minW={{ base: "57px", md: "83px" }}
                className="price-block"
                marginLeft={4}>
                {isLoadingCustomerBestPrices ? (
                  <Spinner color="brand.orange" ml={6} />
                ) : (
                  <Flex align="flex-start" textAlign="right" justify="flex-end">
                    <Text fontSize="4xl" color="brand.orange" fontFamily="Pathway Gothic One">
                      ${price[0]}.
                    </Text>
                    <Text fontSize="md" color="brand.orange" fontFamily="Pathway Gothic One" mt={2}>
                      {price[1]}
                    </Text>
                  </Flex>
                )}
                <Text textAlign="right" fontSize="sm" color="gray.500">
                  /{product?.salesUnitOfMeasure}
                </Text>

                {product.discountType == EProductDiscountType.customerBestPrice && (
                  <Text fontSize="sm" color="brand.orange">
                    {product.lineDiscountPercentage}% off
                  </Text>
                )}
              </Flex>
            )}
          </GridItem>

          <GridItem colSpan={{ base: 8, md: 3, lg: 8 }} gridRow={{ base: "5", md: "auto" }}>
            <Text fontSize="14px" color="gray.400">
              {product?.brandName} | {product?.vendorItemNumber} | {product?.itemLocation}
            </Text>
          </GridItem>

          <GridItem colSpan={{ base: 4, md: 1, lg: 1 }} gridRow={{ base: "7", md: "auto" }}>
            {product.productInventory?.inventory && (
              <Center>
                <ProductInventoryIndicator inventory={product.productInventory?.inventory} />
              </Center>
            )}
          </GridItem>

          <GridItem colSpan={{ base: 8, md: 4, lg: 9 }} gridRow={{ base: "6", md: "auto" }}>
            {product?.specialEquipmentCode && (
              <HStack>
                <Image
                  minW="20px"
                  minH="20px"
                  w="20px"
                  h="20px"
                  src={product?.specialEquipmentCode.imageUrl}
                  alt="special equipment code"
                />
                <Text fontSize="12px" color="background.brandBlueSecondary">
                  {product?.specialEquipmentCode.description}
                </Text>
              </HStack>
            )}
          </GridItem>
          <GridItem colSpan={{ base: 4, md: 2, lg: 1 }} gridRow={{ base: "8", md: "auto" }} textAlign={"center"}>
            {product.discountType == EProductDiscountType.clearancePrice && (
              <Tag
                alignSelf="center"
                fontSize="16px"
                gap={1}
                maxW={compact ? "124px" : "160px"}
                whiteSpace="nowrap"
                borderRadius="100px"
                height="25px"
                background="#0099CC">
                <LightningBolt />
                <span style={{ textOverflow: "ellipsis", overflow: "hidden", color: "#fff" }}>
                  {product.clearanceDiscountPercentage}% off
                </span>
              </Tag>
            )}
          </GridItem>
        </Grid>
      </Box>
    )
  },
)
